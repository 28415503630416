import React, { useState } from "react";
import { backendUrl } from "../../static/js/const";
import { useForm } from "react-hook-form";
import { authAxios } from "../../static/js/util";
import { toast } from 'react-toastify';


const UpdatePasswordModal = ({ setShowPasswordModal }) => {

    const { register, setValue, handleSubmit, errors, watch, setError } = useForm();

    const oldPassword = watch("old_password", "");
    const newPassword = watch("new_password", "");
    const confirmNewPassword = watch("confirm_new_password","");

    const [backendError, setBackendError] = useState("");
    

    const onSubmit = async (data) => {
        console.log(data);
        if (newPassword !== confirmNewPassword) {
            setError("confirm_new_password", { type: "manual", message: "New passwords do not match." });
            return;
        }

        const url = `${backendUrl}/password_update/`;
        try {
            const res = await authAxios.post(url, data);
            console.log(res);
            setShowPasswordModal(false);
            toast.success("Password Updated SuccessFully.!");
        }
        catch (err) {
            console.log(err);
            if (err.response?.status === 400) {
                const backendErrorMessage = err.response.data.old_password 
                    ? err.response.data.old_password[0] 
                    : err.response.data.new_password[0];
                setBackendError(backendErrorMessage);
            } else {
                setBackendError("An error occurred while updating the password.");
            }
        }
        // setShowPasswordModal(false);
    }
  

    return (
        <div
            className="label-modal label-modal--shadow"
        >
            <div className="label-modal__header">
                <p>Update Password</p>
                <button onClick={() => setShowPasswordModal(false)}>
                    <i className="fal fa-times"></i>
                </button>
            </div>
            <div className="label-modal__content">
                
                <div className="UpdatePassword">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="update-fieldset">
                        <input
                        className="sidebar-input border--gray border--onHoverBlue"
                        type="password"
                        name="old_password"
                        placeholder="Old Password"
                        ref={register({ required: "Old password is required" })}
                        />

                        {errors.old_password && <p className="error-text">{errors.old_password.message}</p>}

                        <input
                        className="sidebar-input border--gray border--onHoverBlue"
                        type="password"
                        name="new_password"
                        placeholder="New Password"
                        ref={register({ required: "New password is required" })}
                        />
                        {errors.new_password && <p className="error-text">{errors.new_password.message}</p>}

                        <input
                        className="sidebar-input border--gray border--onHoverBlue"
                        type="password"
                        name="confirm_new_password"
                        placeholder="Confirm New Password"
                        ref={register({ 
                                    required: "Please confirm your new password",
                                    validate: value =>
                                        value === newPassword || "Passwords do not match"
                                })}
                        />     

                        {errors.confirm_new_password && <p className="error-text">{errors.confirm_new_password.message}</p>}
                            
                        {backendError && <p className="error-text">{backendError}</p>}

                        {
                            oldPassword.trim() !== '' && newPassword.trim() !== '' && confirmNewPassword.trim() !== '' ?  
                            <button className="btn update-button" type="submit"> Update Password </button>:
                            <button className="btn btn--disabled update-button" disabled> Update Password </button>
                        }
                    </fieldset>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdatePasswordModal;
