import React, { useState, useContext } from "react";
import logo from "../static/img/logo2.png";
import bg from "../static/img/slideshow-1.jpg";
import { Link } from "react-router-dom";
import { backendUrl } from "../static/js/const";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import useDocumentTitle from "../hooks/useDocumentTitle";

const PasswordResetRequest = ({ }) => {
    useDocumentTitle("Reset Password Request")
    const { register, handleSubmit, errors, watch, setError } = useForm();

    const email = watch("email", "");

    const [backendError, setBackendError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data) => {
        console.log(data);
        if (email === "") {
            setError("email", { type: "manual", message: "Email field Can't be Empty" });
            return;
        }
        setIsSubmitting(true);
        const url = `${backendUrl}/password_reset/`;
        try {
            const res = await axios.post(url, data);
            console.log(res);

            toast.success(res.data.message);
        }
        catch (err) {
            console.log(err);
            if (err.response?.status === 400) {
                const backendErrorMessage = err.response.data.email 
                    ? err.response.data.email[0] 
                    : err.response.data.toString();
                setBackendError(backendErrorMessage);

            } else if (err.response?.status === 404){

                const backendErrorMessage = err.response.data.message;
                setBackendError(backendErrorMessage);
                toast.error(backendErrorMessage);

            } else {
                setBackendError("An error occurred while updating the password.");
            }
        } finally{
            setIsSubmitting(false);
        }
        // setShowPasswordModal(false);
    }
    
    return (
        <>
            {/* <div className="out-of-focus"></div> */}
            <div className="sidebar sidebar--left">
                {/* <div className="sidebar-logo">
                    <Link to='/'>
                        <img
                            className="sidebar-logo__img"
                            src={logo}
                            alt="Board Linker"
                            />
                    </Link>
                </div> */}
                <div style={{marginTop: "20%"}}>
                    <h1 style={{ margin: "10px", fontSize:"21px", fontWeight:"bold"}}>Reset Your Password</h1>
                    <p style={{ margin: "10px", fontSize:"12px"}}>Enter Your Email to send reset Password Link</p>
                </div>
                <div className="reset-password">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="reset-fieldset">
                        <input
                        className="sidebar-input border--gray border--onHoverBlue"
                        type="email"
                        name="email"
                        placeholder="Email to reset"
                        ref={register({ required: "Email is required" })}
                        />

                        {errors.email && <p className="error-text">{errors.email.message}</p>}

                        {backendError && <p className="error-text">{backendError}</p>}

                        <button
                                className={`btn update-button ${isSubmitting ? 'btn--disabled' : ''}`}
                                type="submit"
                                disabled={isSubmitting || email.trim() === ''}
                            >
                                {isSubmitting ? 'Sending...' : 'Send Reset Email'}
                        </button>
                    </fieldset>
                    </form>
                </div>
                
            </div>
            <div className="slideshow desktop" id="slideshow-container">
                <div id="slideshow">
                    <img className="slideshow__img" src={bg} />
                </div>
                <div className="splash-text">
                    <h1 className="splash-text__h1">Plan your tasks</h1>
                    <p> Plan out your tasks and vacations using Board Linker</p>
                </div>
            </div>
        </>
    );
};

export default PasswordResetRequest;

