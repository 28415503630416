import React from 'react';
import ReactDOM from 'react-dom';
import './static/css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from "react-router-dom";
import GlobalProvider from "./context/GlobalProvider";
import { GoogleOAuthProvider } from '@react-oauth/google';


ReactDOM.render(
<GoogleOAuthProvider clientId="456615619293-3dik4epgnp1tg9gj4600p4f4421ddkj0.apps.googleusercontent.com">
  <React.StrictMode>
  	<BrowserRouter>
      <GlobalProvider>
   		 <App />
   	  </GlobalProvider>
   	</BrowserRouter>
  </React.StrictMode>
</GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
