import React from "react";
import { v4 as uuidv4 } from "uuid";

const Labels = ({ labels, full=false }) => {
    if (labels.length === 0) return null;
    full ? labels = labels : labels = labels.slice(0,5);
    full && labels.length > 5 ? labels = (labels.slice(0,5)).push('...') : labels = labels
    return (
        <div className="labels">
            {labels.map((label) => (
                <p
                    className={`labels__label`}
                    key={uuidv4()}
                    style={{ color: `#${label.color}` }}
                    >
                    { full && label.title !== '' ? label.title : '____' }
                </p> 
            ))}
        </div>
    );
};

export default Labels;
