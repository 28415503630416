import React, { useState, useContext } from "react";
import LoginForm from "../components/auth/LoginForm";
import RegisterForm from "../components/auth/RegisterForm";
import AuthError from "../components/modals/AuthError";
import logo from "../static/img/logo2.png";
import bg from "../static/img/slideshow-1.jpg";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { Link } from "react-router-dom";


const Login = ({ isLogin }) => {
    useDocumentTitle(isLogin ? "Login | Board Linker" : "Sign Up | Board Linker");
    const [errmsgs, setErrMsgs] = useState({ msgs: {}, err: false });

    return (
        <>
            <div className="out-of-focus"></div>
            {errmsgs.err ? (
                <AuthError
                    position={{ left: "50%", right: "50%" }}
                    msgs={errmsgs.msgs}
                    setErrMsgs={setErrMsgs}
                    signup={errmsgs.signup}
                />
            ) : null}
            <div className="sidebar sidebar--left">
                <div className="sidebar-logo">
                    <Link to='/'>
                        <img
                            className="sidebar-logo__img"
                            src={logo}
                            alt="Board Linker"
                            />
                    </Link>
                </div>
                {isLogin ? (
                    <LoginForm setErrMsgs={setErrMsgs} />
                ) : (
                    <RegisterForm setErrMsgs={setErrMsgs} />
                )}
                <div style={{display: "flex", justifyContent:"center"}}>
                    <Link to='/password_reset'>
                        <p style={{fontSize:"13px"}}>Forgot Your Password?</p>
                    </Link>
                </div>
            </div>
            <div className="slideshow desktop" id="slideshow-container">
                <div id="slideshow">
                    <img className="slideshow__img" src={bg} />
                </div>
                <div className="splash-text">
                    <h1 className="splash-text__h1">Plan your tasks</h1>
                    <p> Plan out your tasks and vacations using Board Linker</p>
                </div>
            </div>
        </>
    );
};

Login.defaultProps = {
    isLogin: true,
};

export default Login;

