import React, { useState, useEffect, useRef } from 'react';
import bgImage from '../../static/img/bg3.jpg'; 
import Cards from './cards.js';
import { backendUrl } from "../../static/js/const";
import { authAxios } from "../../static/js/util";

const Record = () => {
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [description, setDescription] = useState('');
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [uniqueCode, setUniqueCode] = useState(null);
    const startTimeRef = useRef(null);
    const animationFrameRef = useRef(null);

    useEffect(() => {
        const savedTime = localStorage.getItem('time');
        const savedIsRunning = localStorage.getItem('isRunning') === 'true';
        const savedIsPaused = localStorage.getItem('isPaused') === 'true';
        const savedUniqueCode = localStorage.getItem('uniqueCode');
        const savedStartTime = localStorage.getItem('startTime');

        if (savedTime) setTime(Number(savedTime));
        if (savedIsRunning) setIsRunning(true);
        if (savedIsPaused) setIsPaused(true);
        if (savedUniqueCode) setUniqueCode(savedUniqueCode);
        if (savedStartTime) startTimeRef.current = Number(savedStartTime);

        if (savedIsRunning && savedStartTime) {
            const elapsed = Math.floor((Date.now() - Number(savedStartTime)) / 1000);
            setTime(prevTime => prevTime + elapsed);
            requestAnimationFrame(updateTime);
        }

        return () => cancelAnimationFrame(animationFrameRef.current);
    }, []);

    useEffect(() => {
        if (isRunning && !isPaused) {
            requestAnimationFrame(updateTime);
        } else {
            cancelAnimationFrame(animationFrameRef.current);
        }

        localStorage.setItem('time', time);
        localStorage.setItem('isRunning', isRunning);
        localStorage.setItem('isPaused', isPaused);
        localStorage.setItem('uniqueCode', uniqueCode);
        if (startTimeRef.current) {
            localStorage.setItem('startTime', startTimeRef.current);
        } else {
            localStorage.removeItem('startTime');
        }

        return () => cancelAnimationFrame(animationFrameRef.current);
    }, [isRunning, time, isPaused, uniqueCode]);

    const updateTime = () => {
        if (startTimeRef.current) {
            const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000);
            setTime(elapsed);
        }
        animationFrameRef.current = requestAnimationFrame(updateTime);
    };

    const handleStart = () => {
        if (!isRunning && !isPaused) {
            setIsRunning(true);
            setIsPaused(false);
            startTimeRef.current = Date.now() - time * 1000;
            showPopupMessage('Time is recording.');
            authAxios.post(`${backendUrl}/recorder/login/`)
                .then(response => {
                    console.log('Login response:', response.data);
                    setUniqueCode(response.data.unique_code);
                })
                .catch(error => {
                    console.error('There was an error logging in:', error);
                });
        } else if (!isRunning && isPaused) {
            setIsRunning(true);
            setIsPaused(false);
            startTimeRef.current = Date.now() - time * 1000;
            showPopupMessage('Time is recording again!');
        } else {
            showPopupMessage('Timer is already Running!');
        }
    };

    const handleStop = () => {
        if (isRunning) {
            setIsRunning(false);
            setIsPaused(false);
            setIsFormOpen(true);
            startTimeRef.current = null;
            localStorage.removeItem('time');
            localStorage.removeItem('isRunning');
            localStorage.removeItem('isPaused');
            localStorage.removeItem('uniqueCode');
            localStorage.removeItem('startTime');
        } else {
            showPopupMessage('Please start the timer!');
        }
    };

    const handleSubmit = () => {
        // console.log(time);
        const payload = {
            description: description,
            total_seconds: time,
            unique_code: uniqueCode
        };
        setTime(0);
        authAxios.post(`${backendUrl}/recorder/logout/`, payload)
            .then(response => {
                console.log(response.data);
                setIsFormOpen(false);
                setDescription('');
                setUniqueCode(null);
            })
            .catch(error => console.error('Error stopping the timer:', error));
    };

    const handlePause = () => {
        if (isRunning) {
            setIsRunning(false);
            setIsPaused(true);
            showPopupMessage('Time is paused.');
        } else {
            showPopupMessage('Please start the timer!');
        }
    };

    const showPopupMessage = (message) => {
        setPopupMessage(message);
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
    };

    const buttonsStyle = {
        marginTop: '100px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    };

    const buttonStyle = {
        margin: '0 10px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    };

    const timerDisplayStyle = {
        fontSize: '48px',
        fontWeight: 'bold',
        margin: '20px',
    };

    const popupStyle = {
        position: 'absolute',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#55B4F5',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        zIndex: 1000,
        display: showPopup ? 'block' : 'none',
    };

    const activeButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#007bff',
        cursor: 'not-allowed',
    };
    const pauseButtonStyle = {
        ...buttonStyle,
        backgroundColor: isPaused ? '#F9D765' : '',
    };

    return (
        <div style={containerStyle}>
             {showPopup && <div style={popupStyle}>{popupMessage}</div>}
            <div style={buttonsStyle}>
                <button
                    onClick={handleStart}
                    style={isRunning ? activeButtonStyle : buttonStyle}
                >
                    <i className="fas fa-play"></i> Start
                </button>
                <button onClick={handleStop} style={buttonStyle}>
                    <i className="fas fa-stop"></i> Stop
                </button>
                {isFormOpen && (
                <div className="form-container">
                    <h2>What did you work on today?</h2>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Please describe in 120 characters."
                    ></textarea>
                    <button className="FromSubmitButton" onClick={handleSubmit}>Submit</button>
                </div>
            )}
            {/* Blur background */}
            {isFormOpen && (
                <div className="blur-background" />
            )}

            <style jsx>{`
                .form-container {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: rgba(255, 255, 255, 0.9);
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                    z-index: 1000;
                }
                h2 {
                    color : black;
                }
                textarea {
                    width: 100%;
                    height: 100px;
                    margin-bottom: 10px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    resize: none;
                }

                .FromSubmitButton {
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 5px;
                    padding: 10px 20px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .FromSubmitButton:hover {
                    background-color: #0056b3;
                }

                // Blur background
                body::before {
                    content: '';
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(5px);
                    z-index: 999;
                }
                .blur-background {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(5px);
                    z-index: 999;
                }
            `}</style>
                <button onClick={handlePause} style={pauseButtonStyle}>
                    <i className="fas fa-pause"></i> Pause
                </button>
            </div>
            <div style={timerDisplayStyle}>{formatTime(time)}</div>
            <Cards/>
        </div>
    );
};

const formatTime = (time) => {
    const getSeconds = `0${(time % 60)}`.slice(-2);
    const minutes = `${Math.floor(time / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export default Record;
