import React, { useEffect } from "react";
import board from "../../static/img/board.svg";
import greenface from "../../static/img/greenface.svg";
import { modalBlurHandler, authAxios } from "../../static/js/util";
import { backendUrl } from "../../static/js/const";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import useAxiosGet from "../../hooks/useAxiosGet";
import axios from "axios";

const ShowCalendarModal = ({ setShowCalendarModal }) => {
    useEffect(modalBlurHandler(setShowCalendarModal), []);

    const { data: events } = useAxiosGet("/boards/calendar_events/");

    console.log(events);

    

    return (
        <div className="calendar-wrapper">
            <div className="calendar">
            
                <button className="close-calendar" onClick={() => setShowCalendarModal(false)}>
                        <i className="fal fa-times"></i>
                </button>
                <FullCalendar
                    plugins={[ dayGridPlugin ]}
                    initialView="dayGridMonth"
                    events={events}
                />
            </div>
        </div>
    );
};

export default ShowCalendarModal;
