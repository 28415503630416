import React, { useContext } from "react";
import axios from 'axios';

import globalContext from '../../context/globalContext'
import { backendUrl }  from '../../static/js/const';
import { useForm } from "react-hook-form";
import { useGoogleLogin } from '@react-oauth/google';

const LoginForm = ({setErrMsgs}) => {
  const { register, handleSubmit, watch } = useForm();

  const userName = watch("username", "");
  const userPassword = watch("password", "");
  const { login } = useContext(globalContext);

  const onSubmit = async (data) => {
      const url = `${backendUrl}/token/`;
      try {
        const res = await axios.post(url, data);
        login(res.data);
      }
      catch (err) {
        if (err.response?.status === 401){
            setErrMsgs({signup: false, err: true, msgs: { Invalid: 'username or password'}});
        }
      }
  }
  const loginWithGoogle = useGoogleLogin({
    onSuccess: codeResponse => {
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${codeResponse.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then(async (res) => {
                    var username = res.data['name'];
                    let username_regex = /[^a-zA-Z0-9]+/g;
                    //let regex = /[^a-zA-Z0-9\s]+/g;
                    username = username.replace(username_regex, "");
                    username = username.slice(0, 15);

                    const register_data = {
                        'first_name': res.data['given_name'],
                        'last_name': res.data['family_name'],
                        'username': username,
                        'email': res.data['email'], 
                        'password': res.data['id']
                    }
                        
                    const login_data = {
                        'username': username,
                        'password': res.data['id']
                    }
                    const loginUrl = `${backendUrl}/token/`;
                    const registerUrl = `${backendUrl}/register/`;
                    
                    try { // try login
                        let res = await axios.post(loginUrl, login_data);
                        login(res.data);
                    }
                    catch (err) {
                        if (err.response?.status === 401){ // no user found, register and login again
                            await axios.post(registerUrl, register_data);
                            let res = await axios.post(loginUrl, login_data);
                            login(res.data);
                        }
                    }
                })
                .catch((err) => console.log(err));
    },
    onError: errorResponse => {
        console.log(errorResponse);
    },
    onNonOAuthError: onNonOAuthError => {
        console.log(onNonOAuthError);
    },
    //flow: 'auth-code',
  });

  return (
  <div className="login">
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="login-fieldset">
        <input
          className="sidebar-input border--gray border--onHoverBlue"
          type="text"
          name="username"
          placeholder="Username"
          ref={register({ required: true })}
        />
        <input
          className="sidebar-input border--gray border--onHoverBlue"
          type="password"
          name="password"
          placeholder="Password"
          ref={register({ required: true })}
        />     
          {
            userName.trim() !== '' && userPassword.trim() !== '' ?  
            <button className="btn login-button" type="submit"> Login </button>:
            <button className="btn btn--disabled login-button" disabled> Login </button>
          }
          <a className="btn google-button" onClick={loginWithGoogle}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="2em" height="2em">
                <path fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" />
                <path fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" />
                <path fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" />
                <path fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" />
              </svg>
              Sign in with Google{' '}
          </a>
      </fieldset>
    </form>
  </div>
  );
};

export default LoginForm;