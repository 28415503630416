import React, { useEffect, useState, useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import Labels from "../boards/Labels";
import useAxiosGet from "../../hooks/useAxiosGet";
import useBlurSetState from "../../hooks/useBlurSetState";
import globalContext from "../../context/globalContext";
import { timeSince, modalBlurHandler, authAxios } from "../../static/js/util";
import { backendUrl } from "../../static/js/const";
import { updateCard } from "../../static/js/board";
import ProfilePic from "../boards/ProfilePic";
import LabelModal from "./LabelModal";


const human_readable_datetime = (date) => {

    const isoDate = new Date(date);

    const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short"
    };

    const formattedDate = isoDate.toLocaleString("en-US", options);

    return formattedDate;
}
const human_readable_date = (date) => {

    const isoDate = new Date(date);

    const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    };

    const formattedDate = isoDate.toLocaleString("en-US", options);

    return formattedDate;
}


const ExistingNotifysModal = ({username, date, setShowModal, card, setExistingFetch, existingFetch}) => {
    const url = `${backendUrl}/boards/get_user_notifys/?username=${username}&date=${date}`;
    // const [ notifys, setNotifys ] = useState(null);
    const [ existingNotifys, setExistingNotifys ] = useState(null);
    const [ fetch, setFetch ] = useState(false);

    useEffect(() => {
        // Define an async function to fetch data
        async function fetchData() {
          try {
            const response = await authAxios.get(url);
            setExistingNotifys(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
    
        fetchData(); // Call the async function to fetch data
      }, [fetch]);
    

    return (
        <>
            <div
                id="notify-modal-user"
                className="notify-div notify-existing"
            >
                <ul>
                    <h4>
                        Notifys for {username} on {human_readable_date(date)}
                    </h4>
                    {existingNotifys ? (existingNotifys.notifys.map((n) => (
                        <li
                            key={n.id}
                            onClick={async (e)=>{
                                await authAxios.patch(`${backendUrl}/boards/items/${card.id}/`, {'notify': {'to': n.to, 'date': n.date}});
                                setFetch(!fetch);                                
                            }}
                        >
                            {n.to} - {human_readable_date(n.date)}
                        </li>
                    ))) : (
                        <p>Loading...</p>
                    )}
                </ul>
                <button className="notify-submit" onClick={ async event => {
                    await authAxios.patch(`${backendUrl}/boards/items/${card.id}/`,
                    {'notify': {'to': username, 'date': date}});
                    setShowModal(false);
                    setExistingFetch(!existingFetch)
                }}>
                    Add
                </button>
                <button className="notify-submit" onClick={ event => setShowModal(false)}>
                    Back
                </button>

                
            </div>
        </>
    )
}


const NotifyModal = ({list, card, setShowModal}) => {
    const [ existingNotifys, setExistingNotifys ] = useState(null);
    const [ notifyDate, setNotifyDate ] = useState(null);
    const [ showUserNotify, setShowUserNotify ] = useState(null);  // also used to store user for user notify modal
    const [ users, setUsers ] = useState(null);
    const [ fetch, setFetch ] = useState(false);

    useEffect(() => {
        // Define an async function to fetch data
        async function fetchData() {
          try {
            const response = await authAxios.get(`${backendUrl}/boards/get_notifys/?item=${card.id}`);
            setExistingNotifys(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
    
        fetchData(); // Call the async function to fetch data
      }, [fetch]);

    
    return (
        <div
            id="notify-modal"
        >
            <div
                className="notify-div notify-existing"
            >
                <h4 className="notify-title">Existing Ones</h4>
                <ul>
                    {existingNotifys ? (existingNotifys.notifys.map((n) => (
                        <li
                            key={n.id}
                            onClick={async (e)=>{
                                await authAxios.patch(`${backendUrl}/boards/items/${card.id}/`, {'notify': {'to': n.to, 'date': n.date}});
                                setFetch(!fetch);                                
                            }}
                        >
                            
                            {n.to} - {human_readable_datetime(n.date)}
                        </li>
                    ))) : (
                        <p>Loading...</p>
                    )
                }
                </ul>


            </div>
            <div
                className="notify-div notify-modal-date"
            >
                <h4 className="notify-title">
                    Add New
                </h4>
                <div className="input-box">
                <label htmlFor="notify-date">Date:</label>
                <input
                    type="datetime-local"
                    id="notify-date"
                    onChange={async (event) => {
                        const localDateTime = new Date(event.target.value);
                        const utcDateTime = localDateTime.toISOString();
                        setNotifyDate(utcDateTime);
                    }} 
                />
                </div>
                <div className="input-box">
                <label htmlFor="notify-user">Username:</label>
                <input
                    type="text"
                    id="notify-user"
                    onChange={async (event) => {
                        let data = (await authAxios.get(`${backendUrl}/boards/get_users/?username=${event.target.value}`)).data;
                        const retrievedUsers = data['users'];
                        setUsers(retrievedUsers.length > 0 ? retrievedUsers.slice(0,2) : null);
                    }}
                />
                </div>
                <button className="notify-submit" onClick={event => setShowModal(false)}>
                    Done
                </button>

                {showUserNotify && (
                    <ExistingNotifysModal
                        username={showUserNotify}
                        date={notifyDate}
                        setShowModal={setShowUserNotify}
                        card={card}
                        setExistingFetch={setFetch}
                        existingFetch={fetch}
                    ></ExistingNotifysModal>
                )}

                {users && (
                    
                    <ul>
                    {users.map((user) => (
                        
                        <li key={user[0]}>
                            <button 
                                className="btn btn-sm m-1"
                                onClick={async (e) => {
                                    if( notifyDate ){
                                        console.log(user);
                                        setShowUserNotify(user[0]);
                                        /*
                                        await authAxios.patch(`${backendUrl}/boards/items/${card.id}/`,
                                        {'notify': {'to': user[0], 'date': notifyDate}});
                                        setFetch(!fetch);
                                        */
                                    }
                                }}
                            >{user[0]}</button>
                        </li>
                        ))}
                    </ul>
                    )
                }
                
            </div>

        </div>
    );
}


const EditCardModal = ({ card, list, setShowModal }) => {
    const [editingTitle, setEditingTitle] = useState(false);
    const [editingDescription, setEditingDescription] = useState(false);
    const [changingDueDate, setChangingDueDate] = useState(false);
    const [changingMembers, setChangingMembers] = useState(false);
    const [changingAttachments, setChangingAttachments] = useState(false);
    const [changingLabels, setChangingLabels] = useState(false);
    const [showNotify, setShowNotify] = useState(false);
    const [ users, setUsers ] = useState(null);
    const [ notifyTo, setNotifyTo ] = useState([]);
    const [ changingNotify, setChangingNotify ] = useState(false);
    const [ notifyDate, setNotifyDate ] = useState(null);
    const [ dueDateColor, setDueDateColor ] = useState('');
    const labelElem = useRef(null);

    useEffect(
        () => {
            CalculateDueDateColor(card.due_date, setDueDateColor);
        }
        , [card.due_date]
    );
    const handleDelete = () => {
        authAxios.delete(`${backendUrl}/boards/items/${card.id}/`);
        window.location.reload();
    }
    const changeDueDate = (date) => {
        authAxios.patch(`${backendUrl}/boards/items/${card.id}/`, {'due_date':date});
        card.due_date = date
    }
    const addNotify = (date, to) => {
        authAxios.patch(`${backendUrl}/boards/items/${card.id}/`, {'notify': {'date': date, 'to': to}});
        // card.due_date = date
    }
    const addAttachment = async (att) => {
        const formData = new FormData();
        formData.append("upload", att);
        formData.append("item", card.id);
        let resp = await authAxios.post(`${backendUrl}/boards/attachments/`, formData);
        //card.attachments = [ ...card.attachments, resp.data ]
        window.location.reload();
    }

    const getUsers = async (event) => {
        if(event.target.value === ''){
            setUsers(null);
        } else {
            let data = (await authAxios.get(`${backendUrl}/boards/get_users/?username=${event.target.value}`)).data;
            const retrievedUsers = data['users'];
            setUsers(retrievedUsers.length > 0 ? retrievedUsers.slice(0,2) : null);
        }
    }
    
    

    const ChangeNotifyModal = () => {
      return (
        <div>
          <label htmlFor="member">Date:</label>
          <input
            type="date"
            id="notify-date"
            onChange={async (event) => setNotifyDate(event.target.value)}
        />
          <label htmlFor="member">Username:</label>
          <input
            type="text"
            id="notify-user"
            onChange={async (event) => {
                let data = (await authAxios.get(`${backendUrl}/boards/get_users/?username=${event.target.value}`)).data;
                const retrievedUsers = data['users'];
                setUsers(retrievedUsers.length > 0 ? retrievedUsers.slice(0,2) : null);
            }}
        />
        <button onClick={event => setChangingNotify(false)}>
            Done
        </button>
          {users && (
            <ul>
            {users.map((user) => (
              <li key={user[0]}>
                <button 
                    className="btn btn-sm m-1"
                    onClick={(e) => {
                        if( notifyDate ){
                            authAxios.patch(`${backendUrl}/boards/items/${card.id}/`, {'notify': {'to': user[0], 'date': notifyDate}});
                        }
                    }
                        
                    }
                >{user[0]}</button>
              </li>
            ))}
          </ul>
          )
          }
          
        </div>
      );
    };
    
    const ChangeMembersModal = () => {
      return (
        <div>
          <label htmlFor="member">Username:</label>
          <input
            type="text"
            id="member"
            onChange={event => getUsers(event)}
        />
          {users && (
            <ul>
            {users.map((user) => (
              <li key={user[0]}>
                <button 
                    className="btn btn-sm m-1"
                    onClick={() => {
                        authAxios.patch(`${backendUrl}/boards/items/${card.id}/`, {'assigned_to':user[0]});
                        setChangingMembers(false)
                        window.location.reload();
                    }
                        
                    }
                >{user[0]}</button>
              </li>
            ))}
          </ul>
          )
          }
          
        </div>
      );
    };
    
    /*
    const AddNotifyModal = () => {
    
      return (
        <div>
          <label htmlFor="dateInput">Select a date:</label>
          <input
            type="date"
            id="dateInput"
            onChange={(event) => {changeDueDate(event.target.value); setChangingDueDate(false)}}
            // onChange={handleDateChange(self.value)}
          />
          <input
            type="date"
            id="dateInput"
            onChange={(event) => {changeDueDate(event.target.value); setChangingDueDate(false)}}
            // onChange={handleDateChange(self.value)}
          />
        </div>
      );
    };
    */
    const DateInput = () => {
    
      return (
        <div>
          <label htmlFor="dateInput">Select a date:</label>
          <input
            type="date"
            id="dateInput"
            onChange={(event) => {changeDueDate(event.target.value); setChangingDueDate(false)}}
            // onChange={handleDateChange(self.value)}
          />
        </div>
      );
    };
    const FileInput = () => {
    
        return (
          <div>
            <label htmlFor="dateInput">Select a File:</label>
            <input
              type="file"
              id="fileInput"
              onInput={(event) => {addAttachment(event.target.files[0]); setChangingAttachments(false)}}
              // onChange={handleDateChange(self.value)}
            />
          </div>
        );
      };

    useEffect(modalBlurHandler(setShowModal), []);
    useBlurSetState(".edit-modal__title-edit", editingTitle, setEditingTitle);
    useBlurSetState(
        ".edit-modal__form",
        editingDescription,
        setEditingDescription
    );


    const {
        data: comments,
        addItem: addComment,
        replaceItem: replaceComment,
        removeItem: removeComment,
    } = useAxiosGet(`/boards/comments/?item=${card.id}`);

    return (
        <div className="edit-modal" >
            <button
                className="edit-modal__exit"
                onClick={() => setShowModal(false)}
            >
                <i className="fal fa-times"></i>
            </button>
            <div className="edit-modal__cols">
                <div className="edit-modal__left">
                    <div 
                        ref={labelElem}
                    >
                        <Labels labels={card.labels} full={true}/>
                    </div>
                    {!editingTitle ? (
                        <p
                            onClick={() => setEditingTitle(true)}
                            className="edit-modal__title"
                        >
                            {card.title}
                        </p>
                    ) : (
                        <EditCardTitle
                            list={list}
                            card={card}
                            setEditingTitle={setEditingTitle}
                        />
                    )}
                    <div className="edit-modal__subtitle">
                        in list <span>{list.title}</span> <br/>
                        {card.due_date && (<div style={{color:dueDateColor}}>
                            <p><i className="fal fa-clock"></i> {card.due_date ? card.due_date.slice(0,10) : null}</p>
                        </div>
                        )}
                    </div>

                    <div className="edit-modal__section-header">
                        <div>
                            <i className="fal fa-file-alt"></i> Description
                        </div>
                        {card.description !== "" && (
                            <div>
                                <button
                                    className="btn btn--secondary btn--small"
                                    onClick={() => setEditingDescription(true)}
                                >
                                    <i className="fal fa-pencil"></i> Edit
                                </button>
                            </div>
                        )}
                    </div>

                    {card.description !== "" && !editingDescription && (
                        <p className="edit-modal__description">
                            {card.description}
                        </p>
                    )}

                    {editingDescription ? (
                        <EditCardDescription
                            list={list}
                            card={card}
                            setEditingDescription={setEditingDescription}
                        />
                    ) : (
                        card.description === "" && (
                            <button
                                className="btn btn--secondary btn--small btn--description"
                                onClick={() => setEditingDescription(true)}
                            >
                                Add description
                            </button>
                        )
                    )}

                    <div
                        className="edit-modal__section-header"
                        style={
                            card.attachments.length === 0
                                ? {
                                      marginBottom: "1.75em",
                                  }
                                : null
                        }
                    >
                        <div>
                            <i className="fal fa-paperclip"></i> Attachments
                        </div>
                        <div>
                            { changingAttachments ? FileInput() : (

                            <a className="btn btn--secondary btn--small" onClick={() => {setChangingAttachments(true)}} >
                                <i className="fal fa-plus"></i> Add
                            </a>
                            )

                            }
                        </div>
                    </div>

                    <Attachments attachments={card.attachments} />
                    <CommentForm
                        card={card}
                        style={
                            (comments || []).length === 0
                                ? { marginBottom: 0 }
                                : null
                        }
                        addComment={addComment}
                    />
                    <Comments
                        card={card}
                        comments={comments || []}
                        replaceComment={replaceComment}
                        removeComment={removeComment}
                    />
                </div>

                <div className="edit-modal__right">
                    <div className="edit-modal__section-header">
                        <div>Actions</div>
                    </div>

                    <ul className="edit-modal__actions">
                        <li>
                            {changingLabels && <LabelModal list={list} card={card} cardElem={labelElem} setShowModal={setChangingLabels} addBoardModel={true}/>}
                                <a className="btn btn--secondary btn--small" onClick={()=>setChangingLabels(!changingLabels)}>
                                    <i className="fal fa-tags"></i> Edit Labels
                                </a>
                        </li>
                        <li>
                            {changingMembers ? ChangeMembersModal() : 
                                <a className="btn btn--secondary btn--small" onClick={()=>setChangingMembers(true)}>
                                    <i className="fal fa-user"></i> Change Members
                                </a>
                            }
                        </li>
                        <li>
                            {changingDueDate ? DateInput() : 
                                <>
                                    <a className="btn btn--secondary btn--small" onClick={()=>setChangingDueDate(true)}>
                                        <i className="fal fa-clock"></i> Change Due Date
                                    </a>
                                    <a className="btn btn--secondary btn--small" onClick={()=>{changeDueDate(null); card.due_date = ''}}>
                                        <i className="fal fa-trash"></i>
                                    </a>
                                </>
                            }
                        </li>
                        <li>
                            {showNotify ? (
                                <NotifyModal list={list}
                                card={card}
                                setShowModal={setShowNotify}></NotifyModal>
                            ) : (
                                <>
                                    <a className="btn btn--secondary btn--small" onClick={()=>setShowNotify(true)}>
                                        <i className="fal fa-clock"></i> Add Notify
                                    </a>
                                </>
                            )}
                        </li>
                        <li>
                            <a className="btn btn--secondary btn--small" onClick={handleDelete}>
                                <i className="fal fa-trash"></i> Delete
                            </a>
                        </li>
                    </ul>
                     <Members members={card.assigned_to} />
                </div>
            </div>
        </div>
    );
};

const EditCardTitle = ({ list, card, setEditingTitle }) => {
    const { board, setBoard } = useContext(globalContext);
    const [title, setTitle] = useState(card.title);

    useEffect(() => {
        const titleInput = document.querySelector(".edit-modal__title-edit");
        titleInput.focus();
        titleInput.select();
    }, []);

    const onEditTitle = async (e) => {
        e.preventDefault();
        if (title.trim() === "") return;
        const { data } = await authAxios.put(
            `${backendUrl}/boards/items/${card.id}/`,
            {
                title,
            }
        );
        setEditingTitle(false);
        updateCard(board, setBoard)(list.id, data);
    };

    return (
        <form onSubmit={onEditTitle}>
            <input
                className="edit-modal__title-edit"
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            ></input>
        </form>
    );
};

const EditCardDescription = ({ list, card, setEditingDescription }) => {
    const { board, setBoard } = useContext(globalContext);
    const [description, setDescription] = useState(card.description);

    const onEditDesc = async (e) => {
        e.preventDefault();
        if (description.trim() === "") return;
        const { data } = await authAxios.put(
            `${backendUrl}/boards/items/${card.id}/`,
            {
                title: card.title,
                description,
            }
        );
        setEditingDescription(false);
        updateCard(board, setBoard)(list.id, data);
    };

    return (
        <form className="edit-modal__form" onSubmit={onEditDesc}>
            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add description..."
            ></textarea>
            {description.trim() !== "" && (
                <button type="submit" className="btn btn--small">
                    Save
                </button>
            )}
        </form>
    );
};

const Attachments = ({ attachments }) =>
    attachments.length !== 0 && (
        <ul className="edit-modal__attachments">
            {attachments.map((attachment) => (
                <li key={uuidv4()}>
                    <div className="attachment">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/512px-Typescript_logo_2020.svg.png" />
                        <div className="attachment__content">
                            <div className="attachment__subtitle">
                                {timeSince(attachment.created_at)}
                            </div>
                            <div className="attachment__title">
                                {attachment.title}
                            </div>
                            <div className="attachment__buttons">
                                <a className="btn btn--secondary btn--small" onClick={async ()=>{
                                    let resp = await authAxios.get(`${backendUrl}/boards/attachments/${attachment.id}/`);
                                    let link = resp.data.upload;
                                    window.open(link, '_blank');
                                }}
                                >
                                    <i className="fal fa-download"></i>
                                </a>
                                <a className="btn btn--secondary btn--small" onClick={async ()=>{
                                    let resp = await authAxios.delete(`${backendUrl}/boards/attachments/${attachment.id}/`);
                                    window.location.reload();
                                }}>
                                    <i className="fal fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );

const Comments = ({ card, comments, replaceComment, removeComment }) => {
    const { authUser } = useContext(globalContext);
    const [isEditing, setIsEditing] = useState(false);
    useBlurSetState(".edit-modal__form--comment", isEditing, setIsEditing);

    if (comments.length === 0) return null;

    const onDelete = async (comment) => {
        await authAxios.delete(`${backendUrl}/boards/comments/${comment.id}/`);
        removeComment(comment.id);
    };

    return (
        <ul className="edit-modal__comments">
            {comments.map((comment) => (
                <li key={uuidv4()}>
                    <div className="comment">
                        <div className="comment__header">
                            <div className="comment__header-left">
                                <ProfilePic user={comment.author} />
                                <div className="comment__info">
                                    <p>{comment.author.full_name}</p>
                                    <p>{timeSince(comment.created_at)}</p>
                                </div>
                            </div>
                            {comment.author.username === authUser.username && (
                                <div className="comment__header-right">
                                    <button
                                        onClick={() => setIsEditing(comment.id)}
                                    >
                                        Edit
                                    </button>{" "}
                                    -{" "}
                                    <button onClick={() => onDelete(comment)}>
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                        {isEditing !== comment.id ? (
                            <div className="comment__content">
                                {comment.body}
                            </div>
                        ) : (
                            <CommentForm
                                card={card}
                                comment={comment}
                                replaceComment={replaceComment}
                                setIsEditing={setIsEditing}
                            />
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );
};

const CommentForm = ({
    card,
    comment,
    style,
    addComment,
    replaceComment,
    setIsEditing,
}) => {
    // If comment not null, edit form
    const [commentBody, setCommentBody] = useState(comment ? comment.body : "");

    const onAddComment = async (e) => {
        e.preventDefault();
        if (commentBody.trim() === "") return;
        const { data } = await authAxios.post(
            `${backendUrl}/boards/comments/`,
            {
                item: card.id,
                body: commentBody,
            }
        );
        addComment(data);
        setCommentBody("");
    };

    const onEditComment = async (e) => {
        e.preventDefault();
        if (commentBody.trim() === "") return;
        const { data } = await authAxios.put(
            `${backendUrl}/boards/comments/${comment.id}/`,
            {
                body: commentBody,
            }
        );
        replaceComment(data);
        setIsEditing(false);
    };

    // Modifier is only for useBlurSetState, as doc.querySelector is selecting description form otherwise
    // Only add if comment is not null, otherwise doc.querySelector selects create comment form
    return (
        <form
            className={`edit-modal__form${
                comment ? " edit-modal__form--comment" : ""
            }`}
            style={style}
            onSubmit={comment ? onEditComment : onAddComment}
        >
            <textarea
                placeholder="Leave a comment..."
                value={commentBody}
                onChange={(e) => setCommentBody(e.target.value)}
            ></textarea>
            {commentBody.trim() !== "" && (
                <button className="btn btn--small" type="submit">
                    Comment
                </button>
            )}
        </form>
    );
};

const Members = ({ members }) =>
    members.length !== 0 && (
        <>
            <div className="edit-modal__section-header">
                <div>Members</div>
            </div>
            <ul className="edit-modal__members">
                {members.map((member) => (
                    <li key={uuidv4()}>
                        <ProfilePic user={member} />
                        <p>{member.full_name}</p>
                    </li>
                ))}
            </ul>
        </>
    );

const CalculateDueDateColor = ( due_date, setDueDateColor ) => {
    if(!due_date) return
    due_date = due_date.slice(0,10)
    const [year, month, day] = due_date.split("-").map(Number);

    const constructedDate = new Date(year, month - 1, day);
    const today = new Date();

    if( constructedDate > today ) return
    constructedDate.getDate() == today.getDate() ? setDueDateColor('orange') : setDueDateColor('red')
}

export default EditCardModal;

