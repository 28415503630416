import React, {
    useState,
    useRef,
    useEffect,
    useContext,
    useCallback,
} from "react";
import _ from "lodash";
import logo from "../../static/img/logo2.png";
import SearchModal from "../modals/SearchModal";
import ProfilePic from "../boards/ProfilePic";
import { Link } from "react-router-dom";
import useAxiosGet from "../../hooks/useAxiosGet";
import useBlurSetState from "../../hooks/useBlurSetState";
import { handleBackgroundBrightness } from "../../static/js/util";
import globalContext from "../../context/globalContext";
import NotificationsModal from "../modals/NotificationsModal";
import axios from 'axios';
import { saveAs } from 'file-saver';
import { backendUrl } from "../../static/js/const";
import { Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";


const Header = (props) => {
    const { authUser, board } = useContext(globalContext);
    const { login, logout } = useContext(globalContext);

    const history = useHistory();

    const [searchQuery, setSearchQuery] = useState(""); //This variable keeps track of what to show in the search bar
    const [backendQuery, setBackendQuery] = useState(""); //This variable is used to query the backend, debounced
    const delayedQuery = useCallback(
        _.debounce((q) => setBackendQuery(q), 500),
        []
    );
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const searchElem = useRef(null);
    const [showNotifications, setShowNotifications] = useState(false);
    useBlurSetState(".label-modal", showNotifications, setShowNotifications);

    useEffect(() => {
        if (searchQuery !== "") setShowSearch(true);
        else if (searchQuery === "" && showSearch) setShowSearch(false);
    }, [searchQuery]);

    useEffect(() => {
        if (!showSearch) setSearchQuery("");
    }, [showSearch]);

    const { data: notifications, setData: setNotifications } = useAxiosGet(
        "/notifications/"
    );

    const onBoardPage = props.location.pathname.split("/")[1] === "b";
    const [isBackgroundDark, setIsBackgroundDark] = useState(false);
    useEffect(handleBackgroundBrightness(board, setIsBackgroundDark), [board]);


    const handleDownload = () => {

        //let csv_data = useAxiosGet("/boards/download_boards/");
        const url = backendUrl + '/boards/download_boards/'; 
        const accessToken = localStorage.getItem("accessToken");

        axios.get(url, {
            headers: {
            'Authorization': 'Bearer ' + accessToken,
            },
            //responseType: 'blob', // specify the response type as binary data
        })
            .then(response => {
            // save the file using FileSaver.js
            const file = new File([response.data], 'trelloBoards.csv', { type: 'text/csv;charset=utf-8' });
            saveAs(file);
            })
            .catch(error => {
            console.error(error);
            });
        }

    const handleLogout = () => {
        login({
            authUser: null,
            checkedAuth: true,
            board: null,
            setBoard: null,
            });
        window.location.href = '/login'
    }





    return (
        <>
            <header
                className={`header${
                    isBackgroundDark && onBoardPage
                        ? " header--transparent"
                        : ""
                }`}
            >
                <div className="header__section">
                    <Link to="/">
                        <img className="header__logo" src={logo} />
                    </Link>
                </div>
                <div className="header__section">
                    <ul className="header__list">
                            <li
                                className={`header__li header__li--search${
                                    searchQuery !== "" ? " header__li--active" : ""
                                }`}
                                ref={searchElem}
                            >
                                <button onClick={
                                    () => {setShowSearchInput(!showSearchInput)}
                                }>
                                    <i className="far fa-search"></i>{" "}
                                </button>
                                <input
                                    className="desktop search-input"
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                        delayedQuery(e.target.value);
                                    }}
                                    style={{
                                        display: showSearchInput ? 'block' : 'none'
                                    }}
                                />

                            </li>
                        <span className="desktop-flex">
                            <li className="header__li header__li--icons">
                                <button onClick={handleDownload}>
                                    <i className="fal fa-download"></i>  Download Boards
                                </button>

                            </li>
                            <li className="header__li header__li--icons">

                                <button onClick={handleLogout}>
                                    <div className="desktop">
                                        <i className="fal fa-sign-out"></i>  Logout
                                    </div>
                                    <div className="mobile">
                                        <i className="fal fa-sign-out"></i>
                                    </div>
                                </button>
                            </li>
                        </span>
                        <li className="header__li header__li--notifications header__li--icons">
                            <button onClick={() => setShowNotifications(true)}>
                                <i className="fal fa-bell"></i>
                            </button>
                            {(notifications || []).find(
                                (notification) => notification.unread == true
                                ) && <div className="header__unread"></div>}
                        </li>

                        <li className="header__li header__li--profile">
                            <ProfilePic user={authUser} large={true} />
                            <div className="name">
                                <p>
                                    Hello, 
                                </p>
                                <p>
                                    {authUser.full_name.replace(/ .*/, "")}
                                </p>
                            </div>
                        </li>

                        <li className="header__li header__li--border mobile">
                            <div className="dropdown">
                                <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fal fa-bars"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" onClick={handleDownload}>
                                        <i className="fal fa-download"></i> Download Boards
                                    </a>
                                    <a className="dropdown-item" onClick={handleLogout}>
                                        <i className="fal fa-sign-out"></i>Logout
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="out-of-focus"></div>
            </header>
            {showSearch && (
                <SearchModal
                    backendQuery={backendQuery}
                    searchElem={searchElem}
                    setShowModal={setShowSearch}
                />
            )}
            {showNotifications && (
                <NotificationsModal
                    setShowModal={setShowNotifications}
                    notifications={notifications}
                    setNotifications={setNotifications}
                />
            )}
        </>
    );
};

export default Header;
