import React, { useState } from "react";
import logo from "../static/img/logo2.png";
import bg from "../static/img/slideshow-1.jpg";
import { Link } from "react-router-dom";
import { backendUrl } from "../static/js/const";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import useDocumentTitle from "../hooks/useDocumentTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PasswordResetConfirm = ({ }) => {
    useDocumentTitle("Reset Password")
    const { register, handleSubmit, errors, watch, setError } = useForm();
    const { uid, token } = useParams();
    const history = useHistory();

    console.log(uid,token);

    const newPassword = watch("new_password", "");
    const reNewPassword = watch("re_new_password","");

    const [backendError, setBackendError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data) => {
        console.log(data);
        if (newPassword !== reNewPassword) {
            setError("re_new_password", { type: "manual", message: "New passwords do not match." });
            return;
        }
        setIsSubmitting(true);
        const url = `${backendUrl}/password_reset_confirmb/${uid}/${token}/`;
        try {
            const res = await axios.post(url, data);
            console.log(res);

            toast.success(res.data.message);
            history.push('/login');
        }
        catch (err) {
            console.log(err);
            if (err.response?.status === 400) {
                const backendErrorMessage = err.response.data.error;
                setBackendError(backendErrorMessage);

            } else {
                setBackendError("An error occurred while updating the password.");
            }
        } finally {
            setIsSubmitting(false);
        }
        // setShowPasswordModal(false);
    }
    
    return (
        <>
            <div className="out-of-focus"></div>
            <div className="sidebar sidebar--left">
                {/* <div className="sidebar-logo">
                    <Link to='/'>
                        <img
                            className="sidebar-logo__img"
                            src={logo}
                            alt="Board Linker"
                            />
                    </Link>
                </div> */}
                <div style={{marginTop: "20%"}}>
                    <h3 style={{ margin: "10px", fontSize:"21px", fontWeight:"bolder"}}>Reset Your Password</h3>
                </div>
                <div className="reset-password">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="update-fieldset">
                    <input
                        className="sidebar-input border--gray border--onHoverBlue"
                        type="password"
                        name="new_password"
                        placeholder="New Password"
                        ref={register({ required: "New password is required" })}
                        />
                        {errors.new_password && <p className="error-text">{errors.new_password.message}</p>}

                        <input
                        className="sidebar-input border--gray border--onHoverBlue"
                        type="password"
                        name="re_new_password"
                        placeholder="Confirm New Password"
                        ref={register({ 
                                    required: "Please confirm your new password",
                                    validate: value =>
                                        value === newPassword || "Passwords do not match"
                                })}
                        />     

                        {errors.re_new_password && <p className="error-text">{errors.re_new_password.message}</p>}
                            
                        {backendError && <p className="error-text">{backendError}</p>}

                        
                        <button
                            className={`btn update-button ${isSubmitting ? 'btn--disabled' : ''}`}
                            type="submit"
                            disabled={isSubmitting || newPassword.trim() === '' || reNewPassword.trim() === ''}
                        >
                            {isSubmitting ? 'Reseting...' : 'Reset Password'}
                        </button>
                            
                    </fieldset>
                    </form>
                </div>
                
            </div>
            <div className="slideshow desktop" id="slideshow-container">
                <div id="slideshow">
                    <img className="slideshow__img" src={bg} />
                </div>
                <div className="splash-text">
                    <h1 className="splash-text__h1">Plan your tasks</h1>
                    <p> Plan out your tasks and vacations using Board Linker</p>
                </div>
            </div>
        </>
    );
};

export default PasswordResetConfirm;

