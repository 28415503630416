export const backendUrl = "https://boardlinker.com";
// export const backendUrl = "http://localhost:8000";

export const colors = [
    ["#0079bf", false],
    ["#70b500", false],
    ["#ff9f1a", false],
    ["#eb5a46", false],
    ["#f2d600", false],
    ["#c377e0", false],
    ["#ff78cb", false],
    ["#00c2e0", false],
    ["#51e898", false],
    ["#c4c9cc", false],
];
