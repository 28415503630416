import React, { useState, useEffect, useRef } from 'react';
import { modalBlurHandler, mergeRefs, authAxios } from "../../static/js/util";
import { backendUrl } from "../../static/js/const";

const Cards = () => {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [data, setData] = useState({});

    const containerStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%', // Ensure the container takes the full width of the parent
        flexWrap: 'wrap', // Wrap cards if necessary
        marginTop: "60px",
    };

    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '200px', // Adjust width as needed
        height: "400px",
        margin: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: '5px',
        padding: '20px',
        color: 'white', // Text color to contrast with background
    };

    const cardHeaderStyle = {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
    };

    const cardDetailStyle = {
        fontSize: '14px',
        margin: '5px 0',
    };
    const cardTimeStyle = {
        fontSize : "16px", 
        margin: "5px 0"
    }
    const cardDescriptionStyle = {
        fontSize: '15px',
        margin: '5px 0',
        fontFamily: 'Montserrat, sans-serif',
    }

    useEffect(() => {
        authAxios.get(`${backendUrl}/recorder/weekly-records/`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    const getMaxMinutesEntry = (entries) => {
        return entries.reduce((maxEntry, currentEntry) => {
            return currentEntry.total_minutes > maxEntry.total_minutes ? currentEntry : maxEntry;
        }, entries[0]);
    };

    return (
        <div style={containerStyle}>
            {days.map((day, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardHeaderStyle}>{day}</div>
                    {data[day] && data[day].length > 0 ? (
                        (() => {
                            const maxEntry = getMaxMinutesEntry(data[day]);
                            return (
                                <div key={maxEntry.id}>
                                    <div style={cardTimeStyle}>{maxEntry.total_time}</div>
                                    <div style={cardDetailStyle}>{maxEntry.longin} - {maxEntry.logout}</div>
                                    {/* <div style={cardDetailStyle}>------------------</div> */}
                                    <hr></hr>
                                    <div style={cardDescriptionStyle}>{maxEntry.description || '-'}</div>

                                </div>
                            );
                        })()
                    ) : (
                        <>
                            <div style={cardDetailStyle}>0h 0m</div>
                            {/* <div style={cardDetailStyle}>Description: -</div>
                            <div style={cardDetailStyle}>Login Time: -</div>
                            <div style={cardDetailStyle}>Logout Time: -</div> */}
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};


export default Cards;
